var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x",style:({ backgroundImage: `url(${_vm.backgroundImage})` })},[_vm._m(0),_c('div',{staticClass:"kt-user-card__name"},[_vm._v("Sean Stone")]),_vm._m(1)]),_c('div',{staticClass:"kt-notification"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"kt-notification__custom kt-space-between"},[_c('a',{staticClass:"btn btn-label btn-label-brand btn-sm btn-bold",attrs:{"href":"#"},on:{"click":function($event){return _vm.onLogout()}}},[_vm._v("Sign Out")]),_c('a',{staticClass:"btn btn-clean btn-sm btn-bold",attrs:{"href":"#"},on:{"click":function($event){return _vm.onLogout()}}},[_vm._v("Upgrade Plan")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-user-card__avatar"},[_c('img',{staticClass:"kt-hidden",attrs:{"alt":"Pic","src":require("@/assets/media/users/300_25.jpg")}}),_c('span',{staticClass:"kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"},[_vm._v("S")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kt-user-card__badge"},[_c('span',{staticClass:"btn btn-success btn-sm btn-bold btn-font-md"},[_vm._v("23 messages")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"kt-notification__item",attrs:{"href":"#"}},[_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{staticClass:"flaticon2-calendar-3 kt-font-success"})]),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title kt-font-bold"},[_vm._v("My Profile")]),_c('div',{staticClass:"kt-notification__item-time"},[_vm._v(" Account settings and more ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"kt-notification__item",attrs:{"href":"#"}},[_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{staticClass:"flaticon2-mail kt-font-warning"})]),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title kt-font-bold"},[_vm._v(" My Messages ")]),_c('div',{staticClass:"kt-notification__item-time"},[_vm._v("Inbox and tasks")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"kt-notification__item",attrs:{"href":"#"}},[_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{staticClass:"flaticon2-rocket-1 kt-font-danger"})]),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title kt-font-bold"},[_vm._v(" My Activities ")]),_c('div',{staticClass:"kt-notification__item-time"},[_vm._v("Logs and notifications")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"kt-notification__item",attrs:{"href":"#"}},[_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{staticClass:"flaticon2-hourglass kt-font-brand"})]),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title kt-font-bold"},[_vm._v("My Tasks")]),_c('div',{staticClass:"kt-notification__item-time"},[_vm._v(" latest tasks and projects ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"kt-notification__item",attrs:{"href":"#"}},[_c('div',{staticClass:"kt-notification__item-icon"},[_c('i',{staticClass:"flaticon2-cardiogram kt-font-warning"})]),_c('div',{staticClass:"kt-notification__item-details"},[_c('div',{staticClass:"kt-notification__item-title kt-font-bold"},[_vm._v("Billing")]),_c('div',{staticClass:"kt-notification__item-time"},[_vm._v(" billing & statements "),_c('span',{staticClass:"kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"},[_vm._v("2 pending")])])])])
}]

export { render, staticRenderFns }